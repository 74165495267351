import { AutocompleteOptionType } from "../../../../../common/models/autocomplete";
import { IFormValidationState } from "../../../../../common/models/formValidation";

export interface IPlateNumberExportData {
  "Driver Name": string | undefined;
  "License Plate Number": string | undefined;
  "Category Name": string | undefined;
  Company: string | undefined;
}

export interface IPlateNumberForm {
  plateNumber: string;
  companyName: string;
  driverName: string;
  category: AutocompleteOptionType;
}

export const defaultPlateNumberFormVariables = {
  plateNumber: "",
  companyName: "",
  driverName: "",
  category: null,
};

interface IPlateNumberFormValidationState {
  plateNumber: IFormValidationState;
  companyName: IFormValidationState;
  driverName: IFormValidationState;
  category: IFormValidationState;
}

export const defaultPlateNumberFormValidationState: IPlateNumberFormValidationState =
  {
    plateNumber: {
      hasError: false,
      errorMessage: "",
    },
    companyName: {
      hasError: false,
      errorMessage: "",
    },
    driverName: {
      hasError: false,
      errorMessage: "",
    },
    category: {
      hasError: false,
      errorMessage: "",
    },
  };

export const plateNumberFormRules = {
  plateNumber: {
    required: true,
    unique: true,
    pattern: /^[A-Z0-9]+$/,
    minLength: { value: 6, message: "Must be at least 6 characters" },
    maxLength: { value: 10, message: "Must be no more than 10 characters" },
    containsLetterAndNumber: {
      pattern: /^(?=.*[A-Z])(?=.*\d).+$/,
      message: "Must contain at least one letter and one number",
    },
  },
  companyName: {
    required: true,
    pattern: /^[a-zA-Z0-9\s]+$/,
  },
  driverName: {
    required: true,
    pattern: /^[a-zA-Z0-9\s]+$/,
  },
  category: {
    required: true,
    pattern: /^(?!^ +$)[a-zA-Z0-9_]+(?:[\s_][a-zA-Z0-9_]+)*$/,
  },
};
